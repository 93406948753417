import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { timeZones } from '../../../assets/constants/timezones';


@Injectable({
  providedIn: 'root'
})

export class SiteService {
  url: string;
  caretakerUrl = '';
  haystackTimezoneMap: any = {};
  constructor(private http: HttpClient,
    private configService: ConfigurationService
    ) { 
      this.url = this.configService.getConfig('haystackUrl');
      this.haystackTimezoneMap = timeZones;
    }

  getSites(): Observable<any> {
    const data = `ver:"2.0"\nfilter\n"site"`;
    return this.http.post(`${this.url}v1/read/`, data).pipe(catchError(this.handleError));
  }

  /**
 * Get the logged in user object
 * @returns Logged in user info
 */
 
  getUser() {
    const url = `${this.configService.getConfig('auth').caretakerUrl}/token/userinfo`;
    return this.http.get(url).pipe(catchError(this.handleError));;
  }

  // returns the IANA timezone from a Haystack timezone
  // e.g. when converting tz from site entity
  getIANATimeZone(timezone: string) {
    if (timezone === 'Calcutta') {
      timezone = 'Kolkata';
    }
    return this.haystackTimezoneMap[timezone];
  }

  getReadById(pointId: string) {
    if(!pointId) {
      return of(null);
    }
    const data = `ver:"2.0"\nid\n@${pointId}`;
    return this.http.post(`${this.url}v1/read/`, data).pipe(catchError(this.handleError));
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }

}

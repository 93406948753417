const timeZones = {
	"Abidjan": "Africa/Abidjan",
	"Accra": "Africa/Accra",
	"Addis_Ababa": "Africa/Addis_Ababa",
	"Algiers": "Africa/Algiers",
	"Asmara": "Africa/Asmara",
	"Asmera": "Africa/Asmera",
	"Bamako": "Africa/Bamako",
	"Bangui": "Africa/Bangui",
	"Banjul": "Africa/Banjul",
	"Bissau": "Africa/Bissau",
	"Blantyre": "Africa/Blantyre",
	"Brazzaville": "Africa/Brazzaville",
	"Bujumbura": "Africa/Bujumbura",
	"Cairo": "Africa/Cairo",
	"Casablanca": "Africa/Casablanca",
	"Ceuta": "Africa/Ceuta",
	"Conakry": "Africa/Conakry",
	"Dakar": "Africa/Dakar",
	"Dar_es_Salaam": "Africa/Dar_es_Salaam",
	"Djibouti": "Africa/Djibouti",
	"Douala": "Africa/Douala",
	"El_Aaiun": "Africa/El_Aaiun",
	"Freetown": "Africa/Freetown",
	"Gaborone": "Africa/Gaborone",
	"Harare": "Africa/Harare",
	"Johannesburg": "Africa/Johannesburg",
	"Juba": "Africa/Juba",
	"Kampala": "Africa/Kampala",
	"Khartoum": "Africa/Khartoum",
	"Kigali": "Africa/Kigali",
	"Kinshasa": "Africa/Kinshasa",
	"Lagos": "Africa/Lagos",
	"Libreville": "Africa/Libreville",
	"Lome": "Africa/Lome",
	"Luanda": "Africa/Luanda",
	"Lubumbashi": "Africa/Lubumbashi",
	"Lusaka": "Africa/Lusaka",
	"Malabo": "Africa/Malabo",
	"Maputo": "Africa/Maputo",
	"Maseru": "Africa/Maseru",
	"Mbabane": "Africa/Mbabane",
	"Mogadishu": "Africa/Mogadishu",
	"Monrovia": "Africa/Monrovia",
	"Nairobi": "Africa/Nairobi",
	"Ndjamena": "Africa/Ndjamena",
	"Niamey": "Africa/Niamey",
	"Nouakchott": "Africa/Nouakchott",
	"Ouagadougou": "Africa/Ouagadougou",
	"Porto-Novo": "Africa/Porto-Novo",
	"Sao_Tome": "Africa/Sao_Tome",
	"Timbuktu": "Africa/Timbuktu",
	"Tripoli": "Africa/Tripoli",
	"Tunis": "Africa/Tunis",
	"Windhoek": "Africa/Windhoek",
	"Adak": "America/Adak",
	"Anchorage": "America/Anchorage",
	"Anguilla": "America/Anguilla",
	"Antigua": "America/Antigua",
	"Araguaina": "America/Araguaina",
	"Buenos_Aires": "America/Argentina/Buenos_Aires",
	"Catamarca": "America/Argentina/Catamarca",
	"ComodRivadavia": "America/Argentina/ComodRivadavia",
	"Cordoba": "America/Argentina/Cordoba",
	"Jujuy": "America/Argentina/Jujuy",
	"La_Rioja": "America/Argentina/La_Rioja",
	"Rio_Gallegos": "America/Argentina/Rio_Gallegos",
	"Salta": "America/Argentina/Salta",
	"San_Juan": "America/Argentina/San_Juan",
	"San_Luis": "America/Argentina/San_Luis",
	"Tucuman": "America/Argentina/Tucuman",
	"Ushuaia": "America/Argentina/Ushuaia",
	"Aruba": "America/Aruba",
	"Asuncion": "America/Asuncion",
	"Atikokan": "America/Atikokan",
	"Atka": "America/Atka",
	"Bahia": "America/Bahia",
	"Bahia_Banderas": "America/Bahia_Banderas",
	"Barbados": "America/Barbados",
	"Belem": "America/Belem",
	"Belize": "America/Belize",
	"Blanc-Sablon": "America/Blanc-Sablon",
	"Boa_Vista": "America/Boa_Vista",
	"Bogota": "America/Bogota",
	"Boise": "America/Boise",
	"Cambridge_Bay": "America/Cambridge_Bay",
	"Campo_Grande": "America/Campo_Grande",
	"Cancun": "America/Cancun",
	"Caracas": "America/Caracas",
	"Cayenne": "America/Cayenne",
	"Cayman": "America/Cayman",
	"Chicago": "America/Chicago",
	"Chihuahua": "America/Chihuahua",
	"Coral_Harbour": "America/Coral_Harbour",
	"Costa_Rica": "America/Costa_Rica",
	"Creston": "America/Creston",
	"Cuiaba": "America/Cuiaba",
	"Curacao": "America/Curacao",
	"Danmarkshavn": "America/Danmarkshavn",
	"Dawson": "America/Dawson",
	"Dawson_Creek": "America/Dawson_Creek",
	"Denver": "America/Denver",
	"Detroit": "America/Detroit",
	"Dominica": "America/Dominica",
	"Edmonton": "America/Edmonton",
	"Eirunepe": "America/Eirunepe",
	"El_Salvador": "America/El_Salvador",
	"Ensenada": "America/Ensenada",
	"Fort_Nelson": "America/Fort_Nelson",
	"Fort_Wayne": "America/Fort_Wayne",
	"Fortaleza": "America/Fortaleza",
	"Glace_Bay": "America/Glace_Bay",
	"Godthab": "America/Godthab",
	"Goose_Bay": "America/Goose_Bay",
	"Grand_Turk": "America/Grand_Turk",
	"Grenada": "America/Grenada",
	"Guadeloupe": "America/Guadeloupe",
	"Guatemala": "America/Guatemala",
	"Guayaquil": "America/Guayaquil",
	"Guyana": "America/Guyana",
	"Halifax": "America/Halifax",
	"Havana": "America/Havana",
	"Hermosillo": "America/Hermosillo",
	"Knox": "America/Indiana/Knox",
	"Marengo": "America/Indiana/Marengo",
	"Petersburg": "America/Indiana/Petersburg",
	"Tell_City": "America/Indiana/Tell_City",
	"Vevay": "America/Indiana/Vevay",
	"Vincennes": "America/Indiana/Vincennes",
	"Winamac": "America/Indiana/Winamac",
	"Indianapolis": "America/Indianapolis",
	"Inuvik": "America/Inuvik",
	"Iqaluit": "America/Iqaluit",
	"Jamaica": "America/Jamaica",
	"Juneau": "America/Juneau",
	"Louisville": "America/Kentucky/Louisville",
	"Monticello": "America/Kentucky/Monticello",
	"Knox_IN": "America/Knox_IN",
	"Kralendijk": "America/Kralendijk",
	"La_Paz": "America/La_Paz",
	"Lima": "America/Lima",
	"Los_Angeles": "America/Los_Angeles",
	"Lower_Princes": "America/Lower_Princes",
	"Maceio": "America/Maceio",
	"Managua": "America/Managua",
	"Manaus": "America/Manaus",
	"Marigot": "America/Marigot",
	"Martinique": "America/Martinique",
	"Matamoros": "America/Matamoros",
	"Mazatlan": "America/Mazatlan",
	"Mendoza": "America/Mendoza",
	"Menominee": "America/Menominee",
	"Merida": "America/Merida",
	"Metlakatla": "America/Metlakatla",
	"Mexico_City": "America/Mexico_City",
	"Miquelon": "America/Miquelon",
	"Moncton": "America/Moncton",
	"Monterrey": "America/Monterrey",
	"Montevideo": "America/Montevideo",
	"Montreal": "America/Montreal",
	"Montserrat": "America/Montserrat",
	"Nassau": "America/Nassau",
	"New_York": "America/New_York",
	"Nipigon": "America/Nipigon",
	"Nome": "America/Nome",
	"Noronha": "America/Noronha",
	"Beulah": "America/North_Dakota/Beulah",
	"Center": "America/North_Dakota/Center",
	"New_Salem": "America/North_Dakota/New_Salem",
	"Ojinaga": "America/Ojinaga",
	"Panama": "America/Panama",
	"Pangnirtung": "America/Pangnirtung",
	"Paramaribo": "America/Paramaribo",
	"Phoenix": "America/Phoenix",
	"Port-au-Prince": "America/Port-au-Prince",
	"Port_of_Spain": "America/Port_of_Spain",
	"Porto_Acre": "America/Porto_Acre",
	"Porto_Velho": "America/Porto_Velho",
	"Puerto_Rico": "America/Puerto_Rico",
	"Rainy_River": "America/Rainy_River",
	"Rankin_Inlet": "America/Rankin_Inlet",
	"Recife": "America/Recife",
	"Regina": "America/Regina",
	"Resolute": "America/Resolute",
	"Rio_Branco": "America/Rio_Branco",
	"Rosario": "America/Rosario",
	"Santa_Isabel": "America/Santa_Isabel",
	"Santarem": "America/Santarem",
	"Santiago": "America/Santiago",
	"Santo_Domingo": "America/Santo_Domingo",
	"Sao_Paulo": "America/Sao_Paulo",
	"Scoresbysund": "America/Scoresbysund",
	"Shiprock": "America/Shiprock",
	"Sitka": "America/Sitka",
	"St_Barthelemy": "America/St_Barthelemy",
	"St_Johns": "America/St_Johns",
	"St_Kitts": "America/St_Kitts",
	"St_Lucia": "America/St_Lucia",
	"St_Thomas": "America/St_Thomas",
	"St_Vincent": "America/St_Vincent",
	"Swift_Current": "America/Swift_Current",
	"Tegucigalpa": "America/Tegucigalpa",
	"Thule": "America/Thule",
	"Thunder_Bay": "America/Thunder_Bay",
	"Tijuana": "America/Tijuana",
	"Toronto": "America/Toronto",
	"Tortola": "America/Tortola",
	"Vancouver": "America/Vancouver",
	"Virgin": "America/Virgin",
	"Whitehorse": "America/Whitehorse",
	"Winnipeg": "America/Winnipeg",
	"Yakutat": "America/Yakutat",
	"Yellowknife": "America/Yellowknife",
	"Casey": "Antarctica/Casey",
	"Davis": "Antarctica/Davis",
	"DumontDUrville": "Antarctica/DumontDUrville",
	"Macquarie": "Antarctica/Macquarie",
	"Mawson": "Antarctica/Mawson",
	"McMurdo": "Antarctica/McMurdo",
	"Palmer": "Antarctica/Palmer",
	"Rothera": "Antarctica/Rothera",
	"South_Pole": "Antarctica/South_Pole",
	"Syowa": "Antarctica/Syowa",
	"Troll": "Antarctica/Troll",
	"Vostok": "Antarctica/Vostok",
	"Aden": "Asia/Aden",
	"Almaty": "Asia/Almaty",
	"Amman": "Asia/Amman",
	"Anadyr": "Asia/Anadyr",
	"Aqtau": "Asia/Aqtau",
	"Aqtobe": "Asia/Aqtobe",
	"Ashgabat": "Asia/Ashgabat",
	"Ashkhabad": "Asia/Ashkhabad",
	"Baghdad": "Asia/Baghdad",
	"Bahrain": "Asia/Bahrain",
	"Baku": "Asia/Baku",
	"Bangkok": "Asia/Bangkok",
	"Barnaul": "Asia/Barnaul",
	"Beirut": "Asia/Beirut",
	"Bishkek": "Asia/Bishkek",
	"Brunei": "Asia/Brunei",
	"Calcutta": "Asia/Calcutta",
	"Chita": "Asia/Chita",
	"Choibalsan": "Asia/Choibalsan",
	"Chongqing": "Asia/Chongqing",
	"Chungking": "Asia/Chungking",
	"Colombo": "Asia/Colombo",
	"Dacca": "Asia/Dacca",
	"Damascus": "Asia/Damascus",
	"Dhaka": "Asia/Dhaka",
	"Dili": "Asia/Dili",
	"Dubai": "Asia/Dubai",
	"Dushanbe": "Asia/Dushanbe",
	"Gaza": "Asia/Gaza",
	"Hanoi": "Asia/Hanoi",
	"Harbin": "Asia/Harbin",
	"Hebron": "Asia/Hebron",
	"Ho_Chi_Minh": "Asia/Ho_Chi_Minh",
	"Hong_Kong": "Asia/Hong_Kong",
	"Hovd": "Asia/Hovd",
	"Irkutsk": "Asia/Irkutsk",
	"Istanbul": "Asia/Istanbul",
	"Jakarta": "Asia/Jakarta",
	"Jayapura": "Asia/Jayapura",
	"Jerusalem": "Asia/Jerusalem",
	"Kabul": "Asia/Kabul",
	"Kamchatka": "Asia/Kamchatka",
	"Karachi": "Asia/Karachi",
	"Kashgar": "Asia/Kashgar",
	"Kathmandu": "Asia/Kathmandu",
	"Katmandu": "Asia/Katmandu",
	"Khandyga": "Asia/Khandyga",
	"Kolkata": "Asia/Kolkata",
	"Krasnoyarsk": "Asia/Krasnoyarsk",
	"Kuala_Lumpur": "Asia/Kuala_Lumpur",
	"Kuching": "Asia/Kuching",
	"Kuwait": "Asia/Kuwait",
	"Macao": "Asia/Macao",
	"Macau": "Asia/Macau",
	"Magadan": "Asia/Magadan",
	"Makassar": "Asia/Makassar",
	"Manila": "Asia/Manila",
	"Muscat": "Asia/Muscat",
	"Nicosia": "Asia/Nicosia",
	"Novokuznetsk": "Asia/Novokuznetsk",
	"Novosibirsk": "Asia/Novosibirsk",
	"Omsk": "Asia/Omsk",
	"Oral": "Asia/Oral",
	"Phnom_Penh": "Asia/Phnom_Penh",
	"Pontianak": "Asia/Pontianak",
	"Pyongyang": "Asia/Pyongyang",
	"Qatar": "Asia/Qatar",
	"Qyzylorda": "Asia/Qyzylorda",
	"Rangoon": "Asia/Rangoon",
	"Riyadh": "Asia/Riyadh",
	"Saigon": "Asia/Saigon",
	"Sakhalin": "Asia/Sakhalin",
	"Samarkand": "Asia/Samarkand",
	"Seoul": "Asia/Seoul",
	"Shanghai": "Asia/Shanghai",
	"Singapore": "Asia/Singapore",
	"Srednekolymsk": "Asia/Srednekolymsk",
	"Taipei": "Asia/Taipei",
	"Tashkent": "Asia/Tashkent",
	"Tbilisi": "Asia/Tbilisi",
	"Tehran": "Asia/Tehran",
	"Tel_Aviv": "Asia/Tel_Aviv",
	"Thimbu": "Asia/Thimbu",
	"Thimphu": "Asia/Thimphu",
	"Tokyo": "Asia/Tokyo",
	"Tomsk": "Asia/Tomsk",
	"Ujung_Pandang": "Asia/Ujung_Pandang",
	"Ulaanbaatar": "Asia/Ulaanbaatar",
	"Ulan_Bator": "Asia/Ulan_Bator",
	"Urumqi": "Asia/Urumqi",
	"Ust-Nera": "Asia/Ust-Nera",
	"Vientiane": "Asia/Vientiane",
	"Vladivostok": "Asia/Vladivostok",
	"Yakutsk": "Asia/Yakutsk",
	"Yekaterinburg": "Asia/Yekaterinburg",
	"Yerevan": "Asia/Yerevan",
	"Azores": "Atlantic/Azores",
	"Bermuda": "Atlantic/Bermuda",
	"Canary": "Atlantic/Canary",
	"Cape_Verde": "Atlantic/Cape_Verde",
	"Faeroe": "Atlantic/Faeroe",
	"Faroe": "Atlantic/Faroe",
	"Jan_Mayen": "Atlantic/Jan_Mayen",
	"Madeira": "Atlantic/Madeira",
	"Reykjavik": "Atlantic/Reykjavik",
	"South_Georgia": "Atlantic/South_Georgia",
	"St_Helena": "Atlantic/St_Helena",
	"Stanley": "Atlantic/Stanley",
	"ACT": "Australia/ACT",
	"Adelaide": "Australia/Adelaide",
	"Brisbane": "Australia/Brisbane",
	"Broken_Hill": "Australia/Broken_Hill",
	"Canberra": "Australia/Canberra",
	"Currie": "Australia/Currie",
	"Darwin": "Australia/Darwin",
	"Eucla": "Australia/Eucla",
	"Hobart": "Australia/Hobart",
	"LHI": "Australia/LHI",
	"Lindeman": "Australia/Lindeman",
	"Lord_Howe": "Australia/Lord_Howe",
	"Melbourne": "Australia/Melbourne",
	"NSW": "Australia/NSW",
	"North": "Australia/North",
	"Perth": "Australia/Perth",
	"Queensland": "Australia/Queensland",
	"South": "Australia/South",
	"Sydney": "Australia/Sydney",
	"Tasmania": "Australia/Tasmania",
	"Victoria": "Australia/Victoria",
	"West": "Australia/West",
	"Yancowinna": "Australia/Yancowinna",
	"GMT": "Etc/GMT",
	"GMT+0": "Etc/GMT+0",
	"GMT+1": "Etc/GMT+1",
	"GMT+10": "Etc/GMT+10",
	"GMT+11": "Etc/GMT+11",
	"GMT+12": "Etc/GMT+12",
	"GMT+2": "Etc/GMT+2",
	"GMT+3": "Etc/GMT+3",
	"GMT+4": "Etc/GMT+4",
	"GMT+5": "Etc/GMT+5",
	"GMT+6": "Etc/GMT+6",
	"GMT+7": "Etc/GMT+7",
	"GMT+8": "Etc/GMT+8",
	"GMT+9": "Etc/GMT+9",
	"GMT-0": "Etc/GMT-0",
	"GMT-1": "Etc/GMT-1",
	"GMT-10": "Etc/GMT-10",
	"GMT-11": "Etc/GMT-11",
	"GMT-12": "Etc/GMT-12",
	"GMT-13": "Etc/GMT-13",
	"GMT-14": "Etc/GMT-14",
	"GMT-2": "Etc/GMT-2",
	"GMT-3": "Etc/GMT-3",
	"GMT-4": "Etc/GMT-4",
	"GMT-5": "Etc/GMT-5",
	"GMT-6": "Etc/GMT-6",
	"GMT-7": "Etc/GMT-7",
	"GMT-8": "Etc/GMT-8",
	"GMT-9": "Etc/GMT-9",
	"GMT0": "Etc/GMT0",
	"Greenwich": "Etc/Greenwich",
	"UCT": "Etc/UCT",
	"UTC": "Etc/UTC",
	"Universal": "Etc/Universal",
	"Zulu": "Etc/Zulu",
	"Amsterdam": "Europe/Amsterdam",
	"Andorra": "Europe/Andorra",
	"Astrakhan": "Europe/Astrakhan",
	"Athens": "Europe/Athens",
	"Belfast": "Europe/Belfast",
	"Belgrade": "Europe/Belgrade",
	"Berlin": "Europe/Berlin",
	"Bratislava": "Europe/Bratislava",
	"Brussels": "Europe/Brussels",
	"Bucharest": "Europe/Bucharest",
	"Budapest": "Europe/Budapest",
	"Busingen": "Europe/Busingen",
	"Chisinau": "Europe/Chisinau",
	"Copenhagen": "Europe/Copenhagen",
	"Dublin": "Europe/Dublin",
	"Gibraltar": "Europe/Gibraltar",
	"Guernsey": "Europe/Guernsey",
	"Helsinki": "Europe/Helsinki",
	"Isle_of_Man": "Europe/Isle_of_Man",
	"Jersey": "Europe/Jersey",
	"Kaliningrad": "Europe/Kaliningrad",
	"Kiev": "Europe/Kiev",
	"Kirov": "Europe/Kirov",
	"Lisbon": "Europe/Lisbon",
	"Ljubljana": "Europe/Ljubljana",
	"London": "Europe/London",
	"Luxembourg": "Europe/Luxembourg",
	"Madrid": "Europe/Madrid",
	"Malta": "Europe/Malta",
	"Mariehamn": "Europe/Mariehamn",
	"Minsk": "Europe/Minsk",
	"Monaco": "Europe/Monaco",
	"Moscow": "Europe/Moscow",
	"Oslo": "Europe/Oslo",
	"Paris": "Europe/Paris",
	"Podgorica": "Europe/Podgorica",
	"Prague": "Europe/Prague",
	"Riga": "Europe/Riga",
	"Rome": "Europe/Rome",
	"Samara": "Europe/Samara",
	"San_Marino": "Europe/San_Marino",
	"Sarajevo": "Europe/Sarajevo",
	"Simferopol": "Europe/Simferopol",
	"Skopje": "Europe/Skopje",
	"Sofia": "Europe/Sofia",
	"Stockholm": "Europe/Stockholm",
	"Tallinn": "Europe/Tallinn",
	"Tirane": "Europe/Tirane",
	"Tiraspol": "Europe/Tiraspol",
	"Ulyanovsk": "Europe/Ulyanovsk",
	"Uzhgorod": "Europe/Uzhgorod",
	"Vaduz": "Europe/Vaduz",
	"Vatican": "Europe/Vatican",
	"Vienna": "Europe/Vienna",
	"Vilnius": "Europe/Vilnius",
	"Volgograd": "Europe/Volgograd",
	"Warsaw": "Europe/Warsaw",
	"Zagreb": "Europe/Zagreb",
	"Zaporozhye": "Europe/Zaporozhye",
	"Zurich": "Europe/Zurich",
	"Antananarivo": "Indian/Antananarivo",
	"Chagos": "Indian/Chagos",
	"Christmas": "Indian/Christmas",
	"Cocos": "Indian/Cocos",
	"Comoro": "Indian/Comoro",
	"Kerguelen": "Indian/Kerguelen",
	"Mahe": "Indian/Mahe",
	"Maldives": "Indian/Maldives",
	"Mauritius": "Indian/Mauritius",
	"Mayotte": "Indian/Mayotte",
	"Reunion": "Indian/Reunion",
	"Apia": "Pacific/Apia",
	"Auckland": "Pacific/Auckland",
	"Bougainville": "Pacific/Bougainville",
	"Chatham": "Pacific/Chatham",
	"Chuuk": "Pacific/Chuuk",
	"Easter": "Pacific/Easter",
	"Efate": "Pacific/Efate",
	"Enderbury": "Pacific/Enderbury",
	"Fakaofo": "Pacific/Fakaofo",
	"Fiji": "Pacific/Fiji",
	"Funafuti": "Pacific/Funafuti",
	"Galapagos": "Pacific/Galapagos",
	"Gambier": "Pacific/Gambier",
	"Guadalcanal": "Pacific/Guadalcanal",
	"Guam": "Pacific/Guam",
	"Honolulu": "Pacific/Honolulu",
	"Johnston": "Pacific/Johnston",
	"Kiritimati": "Pacific/Kiritimati",
	"Kosrae": "Pacific/Kosrae",
	"Kwajalein": "Pacific/Kwajalein",
	"Majuro": "Pacific/Majuro",
	"Marquesas": "Pacific/Marquesas",
	"Midway": "Pacific/Midway",
	"Nauru": "Pacific/Nauru",
	"Niue": "Pacific/Niue",
	"Norfolk": "Pacific/Norfolk",
	"Noumea": "Pacific/Noumea",
	"Pago_Pago": "Pacific/Pago_Pago",
	"Palau": "Pacific/Palau",
	"Pitcairn": "Pacific/Pitcairn",
	"Pohnpei": "Pacific/Pohnpei",
	"Ponape": "Pacific/Ponape",
	"Port_Moresby": "Pacific/Port_Moresby",
	"Rarotonga": "Pacific/Rarotonga",
	"Saipan": "Pacific/Saipan",
	"Samoa": "Pacific/Samoa",
	"Tahiti": "Pacific/Tahiti",
	"Tarawa": "Pacific/Tarawa",
	"Tongatapu": "Pacific/Tongatapu",
	"Truk": "Pacific/Truk",
	"Wake": "Pacific/Wake",
	"Wallis": "Pacific/Wallis",
	"Yap": "Pacific/Yap"
};

export { timeZones };
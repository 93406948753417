import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { SelectDropdownComponent } from './components/select-dropdown/select-dropdown.component';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { SiteSequencerTableComponent } from './components/site-sequencer-table/site-sequencer-table.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { SequenceValidationPopupComponent } from './components/sequence-validation-popup/sequence-validation-popup.component';
import { EditAndDeleteConfirmationModalComponent } from './components/edit-and-delete-confirmation-modal/edit-and-delete-confirmation-modal.component';
import { SequenceScopeSelectionModalComponent } from './components/sequence-scope-selection-modal/sequence-scope-selection-modal.component';
import { ErrorTypeLegendsComponent } from './components/error-type-legends/error-type-legends.component';
import { tooltipDirective } from './directives/tooltip.directive';
import { DeliveryLogsTableComponent } from './components/delivery-logs-table/delivery-logs-table.component';
import { CcuLogsModalComponent } from './components/ccu-logs-modal/ccu-logs-modal.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
@NgModule({
  declarations: [
    SelectDropdownComponent,
    LoaderComponent,
    ToastMessageComponent,
    ConfirmModalComponent,
    SiteSequencerTableComponent,
    AccordionComponent,
    SequenceValidationPopupComponent,
    EditAndDeleteConfirmationModalComponent,
    SequenceScopeSelectionModalComponent,
    ErrorTypeLegendsComponent,
    tooltipDirective,
    DeliveryLogsTableComponent,
    CcuLogsModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    NgxJsonViewerModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'This item is actually loading...' }),
  ],
  exports: [
    MaterialModule,
    FormsModule,
    NgxJsonViewerModule,
    SelectDropdownComponent,
    LoaderComponent,
    ToastMessageComponent,
    ConfirmModalComponent,
    AccordionComponent,
    SiteSequencerTableComponent,
    SequenceValidationPopupComponent,
    ErrorTypeLegendsComponent,
    tooltipDirective,
    DeliveryLogsTableComponent,
    NgxSkeletonLoaderModule
  ]
})
export class SharedModule { }

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SiteSequencerService } from 'src/app/components/site-sequencer/services/site-sequencer.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastMessageComponent } from '../toast-message/toast-message.component';
import { FileService } from 'src/app/services/file.service';
import { SiteService } from '../../services/site.service';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import { logsPlaceholderText } from '../../enums/SequenceKeys.enum';
import { CommonService } from 'src/app/services/common.service';

dayjs.extend(utc);
dayjs.extend(timezone);

const CCU_LOGS_CONTAINER = 'seqlogs';

@Component({
  selector: 'app-ccu-logs-modal',
  templateUrl: './ccu-logs-modal.component.html',
  styleUrls: ['./ccu-logs-modal.component.scss']
})

export class CcuLogsModalComponent {
  isLoading: boolean = false;
  table: any;
  ccuList!: any[];
  sequenceList!: any[];
  selectedCCUId!: string;
  selectedSequenceId!: string;
  selectedSiteId!: string;
  headerColumnNames = ['requestTime', 'action'];
  dataSource!: MatTableDataSource<any>;
  displayedColumns!: string[];
  originalTableData: any[] = [];
  ccuLogsSubscription: any;
  ccuLogsRefreshInterval: number = 60000;
  isPreviousLogsExist: boolean = false;
  previousCCULogsRef: any;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  placeholderMessage: any;
  sequenceLogFiles: any[] = [];
  timezone: any;
  tz!: any;
  siteTimeZones: any = {};
  refs: any = {
    siteRef: undefined,
    floorRef: undefined,
    ccuName: undefined,
    zoneName: undefined,
    equipRef: undefined,
    roomRef: undefined,
    gateway: undefined,
    ccuEquipRef: undefined
  };
  
  constructor(private siteSequencerService: SiteSequencerService,
    private toastMessage: MatSnackBar,
    private fileService: FileService,
    private siteService: SiteService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<CcuLogsModalComponent>
  ) { 
    this.dataSource = new MatTableDataSource();
    this.displayedColumns = this.headerColumnNames;
  }

  ngOnInit(): void {
    this.getSiteInfo(this.selectedSiteId);
    this.tz = this.siteService.getIANATimeZone(this.timezone);
    this.setInitialTableData();
    this.getCCUList();
    this.subscribeToCCULogs();
  }

  setInitialTableData() {
    this.table = [];
    this.originalTableData = this.table ? [...this.table] : [];
    this.dataSource.data = this.table;
    this.dataSource.sort = this.sort;
  }

  // Toast Message 
  showToast(type: string, message: string) {
    this.toastMessage.openFromComponent(ToastMessageComponent, {
      duration: 5000,
      data: {
        type: type,
        message: message
      },
    });
  }

  getCCUList() {
    this.siteSequencerService.getCCUAndEquipDetailsForSite(this.selectedSiteId)
    .subscribe((res: any) => {
      if(res) {
        const data = res.ccuDetailsList ? res.ccuDetailsList : [];
        this.ccuList = [];
        if(data.length > 0) {
          data.forEach((item: any) => {
            item['formattedProfile'] = item.ccuName;      
          });
          this.ccuList = data;
        }
        console.log('ccu list -->', data);
      } else {
        this.ccuList = [];
      }
    },(err) => {
      this.ccuList = [];
      this.isLoading = false;
      console.log('error in fetching ccu list -->', err);
    })
  }

  public async getsequenceLogFiles(siteId: string, ccuId: string, skipLoader?: boolean) {
    if (!skipLoader) {
        this.isLoading = true;
    }
    let logFilesResponse;
    try {
        logFilesResponse = await this.fileService.getFileList(CCU_LOGS_CONTAINER, siteId)
        .toPromise()
        .then((res) => {
          if(res && res.data && res.data.length > 0) {
            console.log('res -->', res);
            this.sequenceLogFiles = this.formatFileList(res.data, ccuId);
            console.log('Formatted ccu log files -->', this.sequenceLogFiles);
            if(this.sequenceLogFiles.length > 0) {
              this.table = this.sequenceLogFiles;
              this.originalTableData = this.table ? [...this.table] : [];
              this.dataSource.data = this.table;
              this.dataSource.sort = this.sort;
              this.placeholderMessage = null;
            } else {
             this.placeholderMessage = logsPlaceholderText.NO_LOGS_FOUND;
            }
          } else {
            this.placeholderMessage = logsPlaceholderText.NO_LOGS_FOUND;
          }
        });
    } catch (err) {
        this.showToast('error', "Failed to load ccu log file data");
        this.placeholderMessage = logsPlaceholderText.NO_LOGS_FOUND;
    } finally {
        this.isLoading = false;
    }
    // this.sequenceLogFiles = this.formatFileList(logFilesResponse.data, ccuId);
  }
  
  /**
   * Formats the file list API response to:
   *  1. Extract ccuId from the file name
   *  2. Format the created date as a moment
   *  3. Filters out files from mismatching CCUs
   *  4. Sorts by created date
   *  5. Return only the last 10 files
   */
  public formatFileList(logFilesResponse: any[], ccuId: string): any[] {
    return logFilesResponse
      .map(logFile => ({
        fileId: logFile.fileId,
        ccuId: logFile.fileId.split('_')[0],
        sequenceId: logFile.fileId.split('_')[1],
        createdDate: dayjs(logFile.createdDate).tz(this.tz),
        requestTime: dayjs(logFile.createdDate).tz(this.tz)
      }))
      .filter(logFile => logFile.ccuId === ccuId && logFile.sequenceId === this.selectedSequenceId)
      .sort((a, b) => (b.createdDate.valueOf() - a.createdDate.valueOf()))
      .slice(0, 10)
  }

  async handleDownload(fileId: string) {
    const ccuName = this.ccuList.find(ccu => ccu.ccuId === this.selectedCCUId)?.ccuName;

    let downloadResponse;
    try {
        downloadResponse = await this.fileService.downloadFile(CCU_LOGS_CONTAINER, this.selectedSiteId, fileId).toPromise();
    } catch (err) {
        this.showToast('error', "Failed to download CCU log file");
    }

    const blob = new Blob([downloadResponse], { type: 'application/zip' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.setAttribute("download", `${ccuName?.replace(/[/\\?%*:|"<>]/g, ' ')} Logs.zip`);
    anchor.setAttribute("href", url);
    anchor.click();
  }

  // call this before initializing tz
  getSiteInfo(siteRef: any) {
    const siteInfoSub = this.siteService.getReadById(siteRef).subscribe(({ rows }) => {
        if (rows.length) {
            // got data for site
            if (rows[0] && rows[0].tz) {  
                this.timezone = rows[0].tz;
                this.siteTimeZones[siteRef] = this.siteService.getIANATimeZone(this.timezone);
            }
            this.refs['siteDetails'] = rows[0];
        }
        siteInfoSub.unsubscribe();
    });
  }

  // Request CCU logs on button click.
  requestCCULogs() {
    this.isLoading = true;
    this.setInitialTableData();    
    const payload = {
      "command": "remoteCommand",
      "remoteCmdType": "save_sequencer_logs",
      "siteRef": this.selectedSiteId,
      "level": "system",
      "id": this.selectedCCUId, // CCU Id
      "sequenceId": this.selectedSequenceId,
      "who": `web_internal_${this.commonService.getDisplayId()}`, 
    }
    // Calling silo API to get CCU logs
    this.siteSequencerService.publishRemoteCommand(this.selectedSiteId, payload)
    .subscribe((res) => {
      console.log('res -->', res);
      if(res) {
        this.isLoading = false;
        this.placeholderMessage = logsPlaceholderText.LOGS_LOADING;
        this.getsequenceLogFiles(this.selectedSiteId, this.selectedCCUId, true);
        this.showToast('success', logsPlaceholderText.LOGS_LOADING);
      } else {
        this.placeholderMessage = logsPlaceholderText.NO_LOGS_FOUND;
        this.isLoading = false;
      }
    }, (err: any) => {
      this.showToast('error', err.error.error || 'Something went wrong, please try again');
      this.placeholderMessage = logsPlaceholderText.NO_LOGS_FOUND;
      this.isLoading = false;
    });

  }

  getFormattedColumnName(columnName: string) {
    return this.commonService.getFormattedColumnName(columnName);
  }

  subscribeToCCULogs() {
    this.ccuLogsSubscription = setInterval(() => {
      if(this.selectedCCUId && this.selectedSequenceId) {
        this.getsequenceLogFiles(this.selectedSiteId, this.selectedCCUId, true);
      }
    }, this.ccuLogsRefreshInterval);
  }

  ngOnDestroy() {
    if (this.ccuLogsSubscription) {
      clearInterval(this.ccuLogsSubscription);
    }
  }

}

import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private configService: ConfigurationService,
    private httpClient: HttpClient
  ) { }

  downloadFile(containerName: string, siteId: string, fileId: string): Observable<any> {
    const downloadUrl = `${this.getFileStorageBaseUrl()}/files/${containerName}/${siteId}/${fileId}`;
    return this.httpClient.get(downloadUrl, { responseType: 'arraybuffer' });
  }

  getFileList(containerName: string, siteId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.getFileStorageBaseUrl()}files/${containerName}/${siteId}`);
  }

  getFileStorageBaseUrl() {
    return this.configService.getConfig('filestorageUrl');
  }

}
